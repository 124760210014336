<template>
  <div class="contract-finance">
    <div class="card">
      <img v-if="contract.validStatus == 1" src="/pass.svg" alt="" class="status" />
      <img v-if="contract.validStatus == 8" src="/no_pass.svg" alt="" class="status" />
      <van-cell-group title="合同信息">
        <van-cell
          center
          title-class="cell-title"
          value-class="cell-value"
          title="合同编号"
          :value="contract.contractCode"
        />
        <van-cell
          center
          title-class="cell-title"
          value-class="cell-value"
          title="合同名称"
          :value="contract.contractName"
        />
        <van-cell
          center
          value-class="cell-value"
          title-class="cell-title"
          title="学生姓名"
          :value="contract.studentName"
        />
      </van-cell-group>
    </div>
    <div class="card">
      <van-cell-group title="其他信息" border>
        <van-row class="row row-1" justify="space-between" align="center">
          <van-col span="6">合同状态：</van-col>
          <van-col span="18" class="cell-value-price">{{ contract.validStatusDesc }}</van-col>
          <van-col span="4">原价：</van-col>
          <van-col span="8" class="cell-value-price">￥{{ contract.originalAmount }}</van-col>
          <van-col span="6">优惠金额：</van-col>
          <van-col span="6" class="cell-value-price">￥{{ contract.discountAmount }}</van-col>
          <van-col span="12"
            >钱包抵扣金额：<span class="cell-value-price"
              >￥{{ contract.walletAmount }}</span
            ></van-col
          >
          <van-col span="24"
            >实付金额：<span class="cell-value-price">￥{{ contract.payedAmount }}</span></van-col
          >
          <van-col span="24"
            >支付方式：<span class="blue-txt">{{ contract.payTypeStr }}</span></van-col
          >
        </van-row>
      </van-cell-group>
    </div>
    <div class="card">
      <van-cell-group title="转账凭证" border>
        <van-grid :column-num="3" :border="false" v-if="images.length">
          <van-grid-item v-for="(item, index) in images" :key="index">
            <van-image :src="item" fit="scale-down" @click="handleShowImage(index)" />
          </van-grid-item>
        </van-grid>
        <div v-else class="empty">
          暂未上传凭证
        </div>
      </van-cell-group>
    </div>
    <div class="opt" v-if="contract.validStatus == 3">
      <van-button
        type="info"
        :loading="loading"
        loading-text="审批中..."
        @click="debounceApprove(1)"
        >批准</van-button
      >
      <van-button
        type="danger"
        :loading="loading1"
        loading-text="审批中..."
        @click="debounceApprove(8)"
        >驳回</van-button
      >
    </div>
    <van-dialog
      v-model="showDialog"
      title="选择驳回理由"
      show-cancel-button
      lockScroll
      @confirm="debounceConfirm(8)"
    >
      <van-radio-group v-model="checked">
        <van-radio :name="item.value" v-for="item in reasons" :key="item.value">{{
          item.label
        }}</van-radio>
      </van-radio-group>
    </van-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from 'lodash'
import { ImagePreview } from 'vant'
export default {
  name: 'newContractApprove',
  data() {
    return {
      status: ['待审核', '已生效', '审批驳回', '财务待审批', '财务审批驳回', '审批通过', '已失效'],
      checked: '',
      loading: false,
      loading1: false,
      //获取的详情数据
      contract: {
        validStatus: 0,
      },
      images: [],
      showDialog: false,
      reasons: [
        { label: '支付凭证需要重新上传', value: 3 },
        { label: '合同有误，请重新创建合同', value: 2 },
      ],
    }
  },
  computed: {
    userId() {
      return this.$store.getters.getUserId
    },
  },
  created() {
    if (!this.userId && !sessionStorage.getItem('userId')) {
      this.$toast.fail({ icon: 'close', message: '登录失败', duration: 5000 })
      return false
    }
    if (!this.$route.query.id) {
      this.$toast.fail({ icon: 'close', message: '参数缺失', duration: 5000 })
      return false
    }
    this.debounceConfirm = _.debounce(this.handleApproveAjax, 300)
    this.debounceApprove = _.debounce(this.handleApprove, 300)
    this.handleGetContractDetail()
  },
  methods: {
    handleShowImage(index) {
      ImagePreview({
        images: this.images,
        startPosition: index,
        closeable: true,
      })
    },
    handleGetContractDetail() {
      const toast = this.$toast.loading('加载中')
      this.$http
        .get('/front/qywx/contract/abroadContractDetail', {
          id: this.$route.query.id,
          userId: this.userId,
        })
        .then(res => {
          if (res.code == 1) {
            this.contract = { ...res.data.data }
            this.contract.voucherUrlList.forEach(item => this.images.push(item.url))
          } else {
            toast.clear()
            this.$toast(res.message)
          }
        })
        .catch(err => {
          toast.clear()
          console.log(err)
        })
    },
    handleApprove(type) {
      if (!this.contract.voucherUrlList.length) {
        this.$toast('转账凭证为空！')
        return false
      } else {
        type == 1
          ? this.$dialog
              .confirm({
                title: '提示',
                message: '确认批准申请？',
              })
              .then(() => {
                // on confirm
                this.handleApproveAjax(type)
              })
          : (this.showDialog = true)
      }
    },
    handleApproveAjax(type) {
      const url = '/front/qywx/contract/validAbroadContract'

      const { id } = this.contract
      const { reasons } = this
      const loadingToast = this.$toast.loading({
        message: '审批中',
        duration: 0,
        forbidClick: true,
        overlay: true,
      })
      let params = {
        modifyBy: this.userId,
        id,
        validStatus: type,
      }
      if (type == 1) {
        this.loading = true
      } else {
        params = {
          ...params,
          reason: reasons.find(val => {
            return val.value == this.checked
          }).label,
        }
        this.loading1 = true
      }
      this.$http
        .post(url, { ...params })
        .then(res => {
          if (res.code == 1) {
            loadingToast.clear()
            this.$toast.success({
              message: `审批成功，已${type == 1 ? '批准' : '驳回'}`,
              duration: 2500,
            })
            setTimeout(() => {
              location.reload()
            }, 2500)
          } else {
            this.$toast(res.message)
          }
        })
        .catch(err => {
          loadingToast.clear()
          console.log(err)
        })
    },
  },
}
</script>
<style lang="less" scoped>
.contract-finance {
  padding-bottom: 100px;
  .empty {
    text-align: center;
    line-height: 80px;
    font-size: 14px;
    color: #bdbdbd;
  }
  .opt {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background: #ffffff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    .van-button {
      width: 49%;
    }
  }
  .card {
    position: relative;
    .status {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      width: 100px;
      opacity: 0.8;
    }
    .add {
      position: absolute;
      top: 15px;
      right: 20px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    .van-cell-group__title {
      font-size: 16px;
      color: #444342;
      font-weight: bold;
    }
    .cell-title {
      flex: none;
      font-size: 14px;
    }
    .cell-title-long {
      flex: 2;
      font-size: 14px;
      white-space: pre-wrap;
      word-break: break-all;
      color: #09205f;
      // color: #3975C6;
    }
    .cell-label {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
    }

    .cell-value-price {
      color: #d50020;
      font-size: 14px;
    }
    .row {
      padding: 0 16px;
      .van-col {
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
        white-space: pre-wrap;
        word-break: break-all;
        color: #6a6a6a;
        &:not(:last-of-type) {
          border-right: 1px solid #eee;
        }
      }
    }
    .row-1 {
      .van-col {
        border: none !important;
        line-height: 30px;
        margin: 0;
        text-align: left;
        .blue-txt {
          color: #3975c6;
        }
        .green-txt {
          color: green;
        }
      }
      .cell-value-price {
        color: #d50020;
        font-size: 14px;
        text-align: left;
      }
    }
    .tbody {
      padding-bottom: 10px;
      border-bottom: 1px solid #eeeeee;
      .van-col {
        color: #444342;
      }
    }
  }
  ::v-deep.van-dialog__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .van-radio-group {
      padding: 20px 0;
      .van-radio {
        margin-bottom: 10px;
        font-size: 16px;
        .van-radio__label {
          color: #5a5a5a;
          font-size: 14px;
        }
        .van-radio__icon {
          height: 1.1em;
        }
      }
    }
  }
  .pop-title {
    text-align: center;
    padding-top: 10px;
    line-height: 40px;
  }
  .members {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .bottom-bar {
      width: 100%;
      position: fixed;
      bottom: 0;
      background: #ffffff;
      .has-check {
        width: 100%;
        overflow-x: auto;
        padding: 6px;
        font-size: 12px;
        color: rgba(213, 0, 32, 0.6);
      }
      .bottom-btns {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        .van-button {
          flex: 1;
          width: 0;
          border-radius: 0;
        }
      }
    }
    .van-checkbox-group {
      flex: 1;
      height: 0;
      overflow-y: auto;
      padding-bottom: 80px;
    }
  }
}
</style>
